import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  activateSubscriptionThunk,
  getInformationSecurityQuestionsThunk,
  getInstallationThunk,
  getSubscriptionThunk,
  getAllUserAccountsThunk,
} from "./activationPortalActions";

export interface IActivationPortalState {
  loading: boolean;
  verificationCodeLoading: boolean;
  // ActivationPortalSteps State
  installations: Array<any>;
  subscriptions: Array<any>;
  securityQuestions: Array<any>;
  myAccounts: Array<any>;
  subscribeFormData: any;
  verificationCode: string;
  fromDashboard: boolean;
}

const initialState: IActivationPortalState = {
  loading: false,
  verificationCodeLoading: false,
  installations: [],
  subscriptions: [],
  securityQuestions: [],
  myAccounts: [],
  subscribeFormData: {},
  verificationCode: "A2421BX3",
  fromDashboard: false,
};

const activationPortalSlice = createSlice({
  name: "activationPortal",
  initialState,
  reducers: {
    setSubscribeFormData: (state, action: PayloadAction<Object | null>) => {
      state.subscribeFormData = {
        ...state.subscribeFormData,
        ...action.payload,
      };
    },
    setVerificationCode: (state, action: PayloadAction<string>) => {
      state.verificationCode = action.payload;
    },
    resetVerificationCodeLoading: (state) => {
      state.verificationCodeLoading = false;
    },
    setFromDashboard: (state, action: PayloadAction<boolean>) => {
      state.fromDashboard = action.payload;
    },
    resetSubscribeFormData: (state) => {
      state.subscribeFormData = {};
    },
  },
  extraReducers: (builder) => {
    // Get Installation Action Cases

    builder.addCase(getInstallationThunk.pending, (state) => {
      state.verificationCodeLoading = true;
    });

    builder.addCase(getInstallationThunk.fulfilled, (state, action) => {
      state.installations = action.payload;
      state.verificationCodeLoading = false;
    });

    builder.addCase(getInstallationThunk.rejected, (state) => {
      state.verificationCodeLoading = false;
    });

    // Get Subscription Action Cases
    builder.addCase(getSubscriptionThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSubscriptionThunk.fulfilled, (state, action) => {
      state.subscriptions = action.payload;
      state.loading = false;
    });

    builder.addCase(getSubscriptionThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Information Security Question Action Cases
    builder.addCase(getInformationSecurityQuestionsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      getInformationSecurityQuestionsThunk.fulfilled,
      (state, action) => {
        state.securityQuestions = action.payload;
        state.loading = false;
      }
    );

    builder.addCase(getInformationSecurityQuestionsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Activate Subscription Action Cases
    builder.addCase(activateSubscriptionThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(activateSubscriptionThunk.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(activateSubscriptionThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get All User Accounts
    builder.addCase(getAllUserAccountsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllUserAccountsThunk.fulfilled, (state, action) => {
      state.myAccounts = action.payload;
      state.loading = false;
    });

    builder.addCase(getAllUserAccountsThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setSubscribeFormData,
  setVerificationCode,
  setFromDashboard,
  resetVerificationCodeLoading,
  resetSubscribeFormData,
} = activationPortalSlice.actions;

export * from "./activationPortalActions";
export default activationPortalSlice.reducer;
