import { Box, Skeleton as MuiSkeleton, styled } from "@mui/material";

export const SkeletonWrapper = styled(Box)({
  width: "456px",
});

export const DashboardMapLoaderWrapper = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const DashboardMapWrapper = styled("div")<{
  allObjectsLoading: boolean;
}>(({ allObjectsLoading }) => {
  return {
    filter: allObjectsLoading ? "blur(8px)" : "none",
    transition: "filter 0.3s ease",
  };
});
