import { DateTime } from "luxon";
import moment from "moment";

export const formatTimeWithDay = (timeStamp: string) => {
  const time = DateTime.fromISO(timeStamp, { zone: "utc" }).toFormat(
    "MM/d/yyyy HH:mm:ss"
  );
  return time;
};

export const formatTimeStamp = (timeStamp: string) => {
  const time = DateTime.fromISO(timeStamp, { zone: "utc" }).toFormat(
    "HH:mm:ss"
  );
  return time;
};

export type GroupBy = "day" | "week" | "month" | "year";

export const getDateKey = (date: string, groupBy: GroupBy): string => {
  const parsedDate = DateTime.fromISO(date);
  DateTime.fromISO(date);

  switch (groupBy) {
    case "day":
      return parsedDate.toFormat("EEE, MMMM d, yyyy");
    case "week":
      const startOfWeek = parsedDate.startOf("week");
      const endOfWeek = startOfWeek.endOf("week");
      return `${startOfWeek.toFormat("MMM d yyyy")} - ${endOfWeek.toFormat(
        "MMM d yyyy"
      )}`;
    case "month":
      return parsedDate.toFormat("yyyy-MM");
    case "year":
      return parsedDate.toFormat("yyyy");
    default:
      throw new Error("Invalid groupBy value");
  }
};

export const convertToNormalTime = (dateString: Date, endDate?: boolean) => {
  const date = new Date(dateString);

  if (endDate) {
    date.setHours(23, 59, 59);
  } else {
    date.setHours(0, 0, 0);
  }

  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedTime = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }T${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}.00Z`;

  return formattedTime;
};

export const convertTimeToISO = (timeString: string) => {
  if (timeString === null) return null;
  if (timeString?.includes("z")) return timeString;

  const currentDate = new Date();

  const year = currentDate.getUTCFullYear();
  const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getUTCDate()).padStart(2, "0");

  const date = `${year}-${month}-${day}`;

  const dateTimeString = `${date}T${timeString}:00.000Z`;
  const dateObj = new Date(dateTimeString);

  return dateObj.toISOString();
};

export const convertISOToTime = (isoString: string) => {
  if (isoString === null) return null;

  const dateObj = new Date(isoString);
  const hours = String(dateObj.getUTCHours()).padStart(2, "0");
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const convertToUTC = (deliveryTime: string) => {
  if (deliveryTime === null) return null;

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const timeInUserTZ = moment.tz(deliveryTime, "HH:mm:ss", userTimeZone);

  const timeInUTC = timeInUserTZ.clone().utc();

  return timeInUTC.format("HH:mm:ss");
};

export const convertToLocalTime = (utcTime: string) => {
  if (utcTime === null) return "";

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const timeInUTC = moment.utc(utcTime, "HH:mm:ss");

  const timeInUserTZ = timeInUTC.clone().tz(userTimeZone);

  return timeInUserTZ.format("HH:mm:ss");
};
