import { FC, Fragment, Dispatch, SetStateAction } from "react";
import { Box, useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import { DateRangePicker, Button, toast } from "@vilocnv/allsetra-core";
import { GroupBy } from "app/data/helpers";
import ReportDateFormats from "../ReportDateFormats/ReportDateFormats";
import ExportButtonMenu from "../../ExportButtonMenu/ExportButtonMenu";
import ScheduleReports from "../ScheduleReports/ScheduleReports";
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import { selectAccountFeaturesState } from "app/data/selectors";
import { Export, ExportGrey } from "assets/icons";
import { MuiButton } from "pages/dashboard/Reports/Reports.styled";
import ExportReportsForm from "components/forms/Reports/ExportReportsForm";
import { omit } from "lodash";
import { exportReportThunk } from "app/features";
import { useTranslation } from "react-i18next";

interface Props {
  setFilterOpen: (value: boolean) => void;
  dateFormat: string;
  setDateFormat: Dispatch<SetStateAction<GroupBy>>;
  handleDateChange: (value: any) => void;
  dateValues: any;
  handleDateRangeSubmit: () => void;
  dateRangeLoading: boolean;
  exportHeaders?: Array<any>;
  generateCSVData?: () => any[];
  formattedData?: any;
  generatePDF?: () => void;
  hideExportButton?: boolean;
  hideScheduleButton?: boolean;
  disableExportButton?: boolean;
  exportButtonLoading?: boolean;
  reportType?: number;
  reportExportTypeValue?: number;
  openModal?: boolean;
  setOpenModal?: (value: boolean) => void;
  exportModalButton?: boolean;
  hideDateFormats?: boolean;
  showRideModes?: boolean;
}

const ReportsFilterBar: FC<Props> = ({
  setFilterOpen,
  dateFormat,
  setDateFormat,
  handleDateChange,
  dateValues,
  handleDateRangeSubmit,
  dateRangeLoading,
  exportHeaders = [],
  generateCSVData,
  formattedData,
  generatePDF,
  hideExportButton = false,
  disableExportButton = false,
  exportButtonLoading = false,
  hideScheduleButton = false,
  reportType,
  reportExportTypeValue,
  openModal = false,
  setOpenModal,
  exportModalButton = false,
  hideDateFormats = false,
  showRideModes = false,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { t } = useTranslation();

  const { features } = useAppSelector(selectAccountFeaturesState);

  const { reportsFilterData } = useReportCommonStates();

  const exportReportsSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);
    const { emailAddress, exportType } = values;

    //@ts-ignore
    const updatedPayload = reportsFilterData?.driver
      ? omit(reportsFilterData, "driver")
      : reportsFilterData;

    const payload = {
      reportDefinition: {
        ...updatedPayload,
      },
      emailAddress,
      exportType,
    };

    const { type } = await dispatch(exportReportThunk(payload));

    if (type === "reports/exportReportThunk/fulfilled") {
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm();
      toast.success(t("reports.exportSuccessMessage"));
      setOpenModal && setOpenModal(false);
    } else {
      toast.error("Server Error");
    }
  };

  return (
    <Box my={2} display={"flex"} justifyContent={"space-between"}>
      <Box display={"flex"}>
        <Button
          id="filter-button"
          sx={{ mr: 2 }}
          text="Filter"
          size="small"
          theme={theme}
          onClick={() => setFilterOpen(true)}
        />
        {!hideDateFormats && (
          <ReportDateFormats
            dateFormat={dateFormat}
            setDateFormat={setDateFormat}
          />
        )}
        <DateRangePicker
          name="datepicker"
          onChange={handleDateChange}
          value={dateValues}
          onSubmit={handleDateRangeSubmit}
          disabled={dateRangeLoading ? true : false}
          theme={theme}
        />
      </Box>
      <Box sx={{ display: "flex", gap: "30px" }}>
        {!hideScheduleButton && features.isAutomaticReportsEnabled && (
          <ScheduleReports
            reportType={reportType}
            reportExportTypeValue={reportExportTypeValue}
          />
        )}
        {!hideExportButton && (
          <ExportButtonMenu
            tableHeaders={exportHeaders}
            generateCSVData={generateCSVData ? generateCSVData() : undefined}
            formattedData={formattedData}
            //@ts-ignore
            generatePDF={generatePDF}
            disabled={disableExportButton}
            exportButtonLoading={exportButtonLoading}
          />
        )}
        {exportModalButton && (
          <Fragment>
            <MuiButton
              disabled={disableExportButton}
              id="demo-positioned-button"
              aria-haspopup="true"
              onClick={() => setOpenModal && setOpenModal(true)}
              variant="text"
            >
              {disableExportButton ? <ExportGrey /> : <Export />}
              Export
            </MuiButton>
            <ExportReportsForm
              open={openModal}
              onClose={() => setOpenModal && setOpenModal(false)}
              onSubmit={exportReportsSubmitHandler}
            />
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

export default ReportsFilterBar;
