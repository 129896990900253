import React, { FC, useState, useEffect, useCallback } from "react";
import { GoogleMap, TrafficLayer } from "@react-google-maps/api";
import { PageLoader } from "@vilocnv/allsetra-core";
import { MapContainer } from "./Map.styled";
import Geozone from "../common/Geozone";
import MarkerClusterer from "../common/MarkerClusterer/MarkerClusterer";
import MapLeftTop from "./children/MapLeftTop";
import MapRightTop from "./children/MapRightTop";

// Data
import { isEmpty } from "lodash";
import {
  useAppDispatch,
  useAppSelector,
  useMap,
  useObjectLiveLocation,
} from "hooks";
import { DEFAULT_COORDINATES, googleMapStyles } from "app/data/constants";
import { getObjectDataForMapThunk, setActiveObjectId } from "app/features";
import {
  selectDrawerSelectedAccountId,
  selectObjectMapState,
  selectSpecificObjectState,
} from "app/data/selectors";

interface MapProps {
  center?: {
    lat: number;
    lng: number;
  };
  zoom?: number;
  radius?: number; // Specify the radius around the center for random placement
  objects: Array<any>;
  geozones?: Array<any>;
  height?: string;
  showSearch?: boolean;
  showFilter?: boolean;
  onFilterClick?: () => void;
  objectsMarker?: boolean;
  skipCurrentLocation?: boolean;
  selectedObjectId?: string;
  children?: any;
}

const Map: FC<MapProps> = ({
  center,
  zoom = 8,
  radius = 50,
  objects,
  geozones,
  height,
  showSearch = false,
  showFilter = false,
  onFilterClick,
  objectsMarker = false,
  selectedObjectId,
  children,
}) => {
  const dispatch = useAppDispatch();

  // Global State
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const { markers } = useAppSelector(selectObjectMapState);
  const { activeObjectId } = useAppSelector(selectSpecificObjectState);

  // Local State
  const [selectedCluster, setSelectedCluster] = useState<any>(null);
  const [showSatelliteMode, setShowSatelliteMode] = useState<boolean>(false);
  const [showTraffic, setShowTraffic] = useState<boolean>(false);
  const [showClusters, setShowClusters] = useState<boolean>(true);
  const [shouldRenderGeozone, setShouldRenderGeozone] =
    useState<boolean>(false);
  const [selectedMarkerLoading, setSelectedMarkerLoading] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [showObjectNames, setShowObjectNames] = useState<boolean>(false);

  // Map Hook
  const { isLoaded, loadError, mapRef, centerCoords, onMapLoad } = useMap({
    center: center || DEFAULT_COORDINATES,
    disableNavigator: true,
  });

  useObjectLiveLocation(isSelectOpen);

  useEffect(() => {
    window.localStorage.setItem("selectedObjectId", selectedObjectId ?? "");
  }, [selectedObjectId]);

  useEffect(() => {
    if (geozones && geozones.length > 0) {
      setShouldRenderGeozone(true);
    } else {
      setShouldRenderGeozone(false);
    }
  }, [geozones]);

  const handleMarkerClick = useCallback(
    async (marker: any) => {
      if (isEmpty(marker)) return;

      setSelectedMarkerLoading(true);

      window.localStorage.setItem("selectedObjectId", marker);

      await dispatch(
        getObjectDataForMapThunk({
          accountId: drawerSelectedAccountId || "",
          objectId: marker,
        })
      );

      setSelectedMarkerLoading(false);
    },
    [dispatch, drawerSelectedAccountId]
  );

  const onObjectChange = (value: any) => {
    if (isEmpty(value)) return;

    const objectIndex = objects.findIndex((obj: any) => obj.uniqueId === value);

    if (objectIndex < 0) return;
    //@ts-ignore
    handleMarkerClick(objects[objectIndex].uniqueId);
    dispatch(setActiveObjectId(value || ""));
    mapRef?.setZoom(14);
    mapRef?.panTo({
      lat: objects[objectIndex].location.latitude,
      lng: objects[objectIndex].location.longitude,
    });
  };

  const handleClusterClick = () => {
    setShowClusters((prevState) => !prevState);
    setSelectedCluster(null);
  };

  useEffect(() => {
    handleMarkerClick(activeObjectId);
  }, [activeObjectId]);

  if (!isLoaded) {
    return <PageLoader />;
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return (
    <MapContainer height={height || "100vh"}>
      <MapRightTop
        showSatelliteMode={showSatelliteMode}
        setShowSatelliteMode={setShowSatelliteMode}
        showClusters={showClusters}
        showTraffic={showTraffic}
        setShowTraffic={setShowTraffic}
        handleClusterClick={handleClusterClick}
        showObjectNames={showObjectNames}
        setShowObjectNames={setShowObjectNames}
        objectsMarker={objectsMarker}
      />
      <MapLeftTop
        markers={markers}
        showSearch={showSearch}
        showFilter={showFilter}
        onObjectChange={onObjectChange}
        onFilterClick={onFilterClick}
        setIsSelectOpen={setIsSelectOpen}
      />
      <GoogleMap
        mapTypeId={
          showSatelliteMode
            ? google.maps.MapTypeId.HYBRID
            : google.maps.MapTypeId.ROADMAP
        }
        center={centerCoords}
        zoom={zoom}
        onLoad={onMapLoad}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{ styles: googleMapStyles }}
      >
        {showTraffic && <TrafficLayer />}
        {shouldRenderGeozone && geozones && (
          <Geozone
            geozone={geozones}
            radius={radius}
            objectsMarker={objectsMarker}
          />
        )}
        {markers.length && (
          <MarkerClusterer
            map={mapRef}
            markers={markers}
            selectedMarkerLoading={selectedMarkerLoading}
            showClusters={showClusters}
            selectedCluster={selectedCluster}
            setSelectedCluster={setSelectedCluster}
            showObjectNames={showObjectNames}
          />
        )}
        {children}
      </GoogleMap>
    </MapContainer>
  );
};

export default React.memo(Map);
