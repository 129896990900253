import { ITagPosition } from "app/data/types";
import { GroupBy, getDateKey } from "../reportCommonHelpers";
import * as Yup from "yup";

//Tag Positions
export const reportsTagFilterFormInitialValues = {
  objectType: [],
  group: [],
  object: [],
  daysOfWeek: [],
  startTime: null,
  endTime: null,
};

export const reportsTagFilterFormValidationSchema: Yup.Schema = Yup.object({
  objectType: Yup.array().of(Yup.string()).label("Object Types"),
  group: Yup.array().of(Yup.string()).label("Groups"),
  object: Yup.array().of(Yup.string()).label("Objects"),
});

export const organizeData = (
  data: ITagPosition[],
  groupBy: GroupBy
): { label: string; records: ITagPosition[] }[] => {
  const organizedData: { [key: string]: ITagPosition[] } = {};

  data.forEach((entry: ITagPosition) => {
    const dateKey = getDateKey(entry.date, groupBy);

    if (!organizedData[dateKey]) {
      organizedData[dateKey] = [];
    }

    organizedData[dateKey].push(entry);
  });

  const result: { label: string; records: ITagPosition[] }[] = Object.keys(
    organizedData
  ).map((dateKey) => {
    return {
      label: dateKey,
      records: organizedData[dateKey],
    };
  });

  return result;
};
