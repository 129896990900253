import { FC, useEffect, useMemo, useState } from "react";
import { CircularProgress, useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import { MapFilterForm, toast, types } from "@vilocnv/allsetra-core";
import Map from "components/maps/Map/Map";
import { MapWrapper, TopRightSection } from "components/maps/Map/Map.styled";

// Data
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getObjectsLocationsThunk,
  getObjectTypesByAccountThunk,
  getAllKeysByAccountThunk,
  getAllAccountGroupsThunk,
  resetAllObjects,
  setFilters,
  setActiveObjectId,
} from "app/features";
import {
  selectAccountKeysState,
  selectDrawerSelectedAccountId,
  selectObjectTypesState,
  selectObjectsState,
  selectAccountGroups,
  selectMapFilterState,
} from "app/data/selectors";
import { useTranslation } from "react-i18next";
import {
  DashboardMapLoaderWrapper,
  DashboardMapWrapper,
} from "./DashboardMap.styled";

const DashboardMap: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );
  const { objectTypes, loading: objectTypesLoading } = useAppSelector(
    selectObjectTypesState
  );
  const { accountGroups, loading: accountGroupsLoading } =
    useAppSelector(selectAccountGroups);
  const { allObjects, loading: allObjectsLoading } =
    useAppSelector(selectObjectsState);
  const { mapFilters } = useAppSelector(selectMapFilterState);

  // Local State
  const [filterOpen, setFilterOpen] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  const activeAccountKeys = useMemo(() => {
    return Array.isArray(accountKeys)
      ? accountKeys.filter((key) => key.isActive)
      : [];
  }, [accountKeys]);

  const mapFiltersSubmitHanlder = async (
    values: types.IMapFilter,
    formikHelpers: FormikHelpers<types.IMapFilter>
  ) => {
    if (!drawerSelectedAccountId) {
      toast.info("Kindly select an account from the navigation drawer.");
      return;
    }

    formikHelpers.setSubmitting(true);
    setSubmitting(true);

    const payload = {
      accountId: drawerSelectedAccountId ?? "",
      values: {
        ...values,
      },
    };

    const { type } = await dispatch(getObjectsLocationsThunk(payload));

    if (type === "objects/getObjectsLocationsThunk/fulfilled") {
      formikHelpers.setSubmitting(false);
      setSubmitting(false);
      setFilterOpen(false);
      dispatch(setFilters(values));
    }
  };

  useEffect(() => {
    if (!isEmpty(drawerSelectedAccountId)) {
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId || ""));
      dispatch(getObjectTypesByAccountThunk(drawerSelectedAccountId || ""));
      dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId || ""));

      dispatch(
        getObjectsLocationsThunk({
          accountId: drawerSelectedAccountId || "",
          values: mapFilters,
        })
      );
    } else {
      dispatch(resetAllObjects());
    }

    return () => {
      dispatch(setActiveObjectId(null));
      dispatch(resetAllObjects());
    };
  }, [drawerSelectedAccountId]);

  return (
    <MapWrapper>
      <TopRightSection />
      <MapFilterForm
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        onSubmit={mapFiltersSubmitHanlder}
        groups={accountGroups}
        types={objectTypes}
        keys={activeAccountKeys}
        dataLoading={
          accountKeysLoading || objectTypesLoading || accountGroupsLoading
        }
        theme={theme}
        initialValues={mapFilters}
        translator={t}
        submitting={submitting}
      />
      {allObjectsLoading && (
        <DashboardMapLoaderWrapper>
          <CircularProgress sx={{ color: "#1976d2" }} />
        </DashboardMapLoaderWrapper>
      )}
      <DashboardMapWrapper allObjectsLoading={allObjectsLoading}>
        <Map
          objects={allObjects}
          onFilterClick={() => setFilterOpen(true)}
          skipCurrentLocation
          showSearch
          showFilter
        />
      </DashboardMapWrapper>
    </MapWrapper>
  );
};

export default DashboardMap;
